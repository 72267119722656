<template>
	<div class="detailContent">
		<el-form :model="dataForm" :rules="dataRule" ref="formData" @keyup.enter.native="dataFormSubmit()"
			label-width="80px">
			<el-form-item label="终端" prop="end">
				<el-radio-group v-model="dataForm.end">
					<el-radio v-for="(item, index) in endList" :label="index + 1" :key="index + 1">{{ item }}
					</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="标题" prop="title">
				<el-input v-model="dataForm.title" placeholder="请输入banner标题"></el-input>
			</el-form-item>
			<el-form-item label="跳转地址" prop="href">
				<el-input v-model="dataForm.href" placeholder="请输入跳转地址"></el-input>
			</el-form-item>
			<el-form-item label="权重" prop="weight">
				<el-input v-model="dataForm.weight" placeholder="请输入权重"></el-input>
			</el-form-item>
			<el-form-item label="图片" prop="imgUrl">
				<el-upload
					action=""
					list-type="picture-card"
					:limit="1"
					:on-preview="handlePreview"
					:show-file-list="false"
					:auto-upload="false"
					:before-upload="beforeImgUpload"
					:on-change="handleImgSuccess"
					accept="image/jpeg,image/png"
					:headers="uploadHeaders"
				>
					<img v-if="dataForm.imgUrl" :src="dataForm.imgUrl" alt="..." class="avatar" />
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</el-form-item>
			<el-dialog :visible.sync="dialogVisible">
				<img width="100%" :src="dialogImageUrl" alt="">
			</el-dialog>
			<el-form-item label="状态" prop="status">
				<el-radio-group v-model="dataForm.status">
					<el-radio v-for="(item, index) in statusList" :label="index + 1" :key="index + 1">{{ item }}
					</el-radio>
				</el-radio-group>
			</el-form-item>

			<el-form-item class="buttons">
				<el-button @click="back()">取消</el-button>
				<el-button type="primary" @click="dataFormSubmit()">确认提交</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
import OSS from 'ali-oss';
import md5 from 'md5';
export default {
	name: 'bannerOption',
	data () {
		return {
			endList: ['医生端', '患者端'],
			statusList: ['启用', '禁用'],
			imgList: [],
			dataForm: {
				id: 0,
				end: 1,
				status: 1,
				title: '',
				imgUrl: '',
				href: '',
				weight: 0
			},
			dataRule: {
				imgUrl: [
					{ required: true, message: '请上传图片', trigger: 'blur' }
				],
				title: [
					{ required: true, message: '请输入标题', trigger: 'blur' }
				],
				href: [
					{ required: true, message: '请输入跳转地址', trigger: 'blur' }
				],
				weight: [
					{ required: true, message: '请输入权重', trigger: 'blur' }
				],
				end: [
					{ required: true, message: '请选择终端', trigger: 'blur' }
				],
				status: [
					{ required: true, message: '请选择状态', trigger: 'blur' }
				]
			},
			uploadUrl: process.env.VUE_APP_BASE_URL + '/mnt/upload-file',
			uploadHeaders: {},
			dialogImageUrl: '',
			dialogVisible: false
		};
	},
	beforeCreate () {
		if (this.$route.query.id) {
			this.$route.meta.navName = '修改banner';
		} else {
			this.$route.meta.navName = '新增banner';
		}
	},
	created () {
		if (this.$route.query.id) {
			this.getDetail(this.$route.query.id);
		}
	},
	methods: {
		back () {
			this.$router.go(-1);
		},
		getDetail (id) {
			this.$root.request('bannerDetail', { id }).then((data) => {
				if (data) {
					this.dataForm = {
						id: this.$route.query.id,
						title: data.title,
						imgUrl: data.imgUrl,
						weight: data.weight,
						status: data.status,
						end: data.end,
						href: data.href
					};
					if (data.imgUrl) this.imgList.push({ url: data.imgUrl, response: { data: { url: data.imgUrl } } });
				}
			});
		},
		dataFormSubmit () {
			this.$refs.formData.validate((valid) => {
				if (valid) {
					this.$root.request('bannerAddOrUpdate', {
						...this.dataForm
					}).then((data) => {
						if (data) {
							this.$root.elMsg('操作成功', 'success', () => {
								if (this.$route.query.id) {
									this.back();
								} else {
									this.$refs.formData.resetFields();
								}
							}, 1000);
						}
					}).catch(e => {
						this.$root.elMsg(e.msg, 'error');
					});
				} else return false;
			});
		},
		beforeImgUpload (file) {
			if (['image/jpeg', 'image/png'].indexOf(file.type) === -1) {
				this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
				return false;
			}
			return true;
		},
		handlePreview (file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		getTokenAndUpload (file, cb) {
			this.$root.request('getOSSToken', {
				page: 1,
				limit: 1000
			}).then((data) => {
				file.status = 'uploading';
				const imgType = file.raw.type.split('/')[1];
				const filename = file.raw.name + file.raw.size; // md5对图片名称进行加密
				const keyValue = '' + md5(new Date() + filename) + '.' + imgType;
				const client = new OSS({
					region: data.region,
					// secure: true,
					stsToken: data.SecurityToken,
					accessKeyId: data.AccessKeyId,
					accessKeySecret: data.AccessKeySecret,
					bucket: data.bucket
				});
				client
					.put('odat/mnt/banner/' + keyValue, file.raw)
					// .multipartUpload(keyValue, file.file)
					.then((res) => {
						if (res.url) {
							file.url = res.url;
							file.status = 'uploaded';
							file.message = '';
							cb(res);
						} else {
							file.status = 'failed';
							this.$root.elMsg('图片上传失败', 'error');
							cb();
						}
					})
					.catch((err) => {
						console.log('message', err.message);
						file.status = 'failed';
						this.$root.elMsg('图片上传失败', 'error');
						cb();
					});
			});
		},
		handleImgSuccess (file, fileList) {
			this.getTokenAndUpload(file, (res) => {
				if (res) {
					this.imgList = [{ url: res.url, response: { data: { url: res.url } } }];
					this.dataForm.imgUrl = this.imgList[0].response.data.url;
				}
			});
		}
	}
};
</script>
<style lang="less" scoped>
/deep/ .el-input {
	width: auto !important;
}
.avatar {
		width: 100%;
		height: 100%;
		display: block;
	}
</style>
<style>
.hide .el-upload--picture-card {
    display: none;
}
</style>